.single-card {
  width: 100%;
  height: 100%;
  display: flex;
}

.single-card.dx-card {
  width: 330px;
  margin: auto auto;

  .screen-x-small & {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    border: 0;
  }  
  
  .content {
    padding: 40px;
  }  
}
