.header {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.header > div {
  flex-grow: 0;
  flex-basis: 100px;
  height: 30px;
}